@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    font-family: "Poppins", sans-serif;
}

.container {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 200px;
    width: 600px;
    background: white;
    padding-bottom: 30px;
    border-radius: 20px;
    box-shadow: 
    0 0 5px rgba(0, 0, 0, 0.1),
    0 0 10px rgba(0, 0, 0, 0.2),
    0 0 20px rgba(0, 0, 0, 0.3); 
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    width: 100%;
    margin-top: 30px;
}

.text {
    color: #1B1A55;
    font-size: 38px;
    font-weight: 700;
}

.inputs {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.input {
    display: flex;
    align-items: center;
    margin: auto;
    width: 480px;
    height: 80px;
    background: #eaeaea;
    border-radius: 20px;
}

.logo {
    height: 130px;
    width: 130px;
    border-radius: 50%;
    
}

.img {
    margin: 0px 30px;
}

.input input {
    height: 50px;
    width: 400px;
    background: transparent;
    border: none;
    outline: none;
    color: #797979;
    font-size: 19px;
}

.forgot-password {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    padding-bottom: 20px;
    color: #797979;
    font-size: 18px;
}

.link {
    margin-left: 15px;
    color: blue;
    cursor: pointer;
    border: none;
    background: none;
    font-size: 18px;
}

.submit-container {
    display: flex;
    gap: 30px;
    margin: 60px auto;
}

.login {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 480px;
    height: 59px;
    color: #fff;
    background: #1B1A55;
    border-radius: 20px;
    font-size: 19px;
    font-weight: 700;
    cursor: pointer;
}

.register {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    padding-bottom: 20px;
    color: #797979;
    font-size: 20px;
    font-weight: 600;
}

.register span {
    color: #797979;
    cursor: pointer;
}

.register span:hover {
    color: #1B1A55;
}